import React from "react";
import styled from "styled-components";
import TimeZoneSelect from "../common/time_zone_select";
const UserBasicInfoForm = ({
  handleSubmit,
  handleInputChange,
  handlePhoneChange,
  handleTimeZoneChange,
  setRoleId,
  setFullTime,
  setContractor,
  setWorkLocation,
  handleAdditionPermission,
  deleteUser,
  state,
  accountView,
  currentUserRoleId,
}) => {
  const {
    userName,
    phone,
    email,
    secondEmail,
    defaultWorkFromHome,
    roleId,
    fullTime,
    contractor,
    taskboardAccess,
    timeZone,
  } = state;
  const getRoleName = (roleId) => {
    const roleNames = {
      1: "Tester",
      2: "Lead",
      3: "PM",
      4: "HR",
      5: "Admin",
    };
    return roleNames[roleId] || "";
  };

  return (
    <Form onSubmit={handleSubmit} id="edit_user_1" className="edit_user">
      <SectionHeader>Personal Information</SectionHeader>
      <FlexWrapper style={{ marginBottom: "16px" }}>
        <InputContainer width="250px">
          <InputLabel htmlFor="user_name">
            Name<span style={{ color: "#519acc" }}>*</span>
          </InputLabel>
          <Input
            onChange={handleInputChange}
            style={{ width: "250px" }}
            className="browser-default text-input-v2"
            maxLength="30"
            required="required"
            type="text"
            value={userName}
            name="userName"
            id="user_name"
            placeholder="Name"
          />
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="user_phone_number">Phone Number</InputLabel>
          <Input
            style={{ width: "250px" }}
            type="text"
            className="browser-default text-input-v2"
            value={phone}
            maxLength={14}
            onChange={handlePhoneChange}
            name="user[phone_number]"
            id="user_phone_number"
            placeholder="Phone Number"
          />
        </InputContainer>
      </FlexWrapper>
      <FlexWrapper style={{ marginBottom: accountView ? "16px" : "32px" }}>
        <InputContainer width="400px">
          <InputLabel htmlFor="user_email">
            Email Address<span style={{ color: "#519acc" }}>*</span>
          </InputLabel>
          <Input
            onChange={handleInputChange}
            style={{ width: "250px" }}
            className="browser-default text-input-v2"
            type="email"
            required="required"
            value={email}
            name="email"
            id="user_email"
            placeholder="Email"
          />
        </InputContainer>
        <InputContainer width="400px">
          <InputLabel htmlFor="user_secondary_email">
            Secondary Email Address
          </InputLabel>
          <Input
            onChange={handleInputChange}
            style={{ width: "250px" }}
            className="browser-default text-input-v2"
            type="email"
            defaultValue={secondEmail}
            name="secondEmail"
            id="user_secondary_email"
            placeholder="Secondary email"
          />
        </InputContainer>
      </FlexWrapper>
      {/* only show if accountView is true */}
      {accountView && (
        <FlexWrapper style={{ marginBottom: "32px" }}>
          <InputContainer width="400px">
            <InputLabel htmlFor="timezone">Time Zone</InputLabel>

            <TimeZoneSelect
              onChange={handleTimeZoneChange}
              placeholder="Select Timezone"
              value={timeZone}
              menuPlacement="top"
              name="timezone"
              customStyles={{
                container: (styles) => ({
                  ...styles,
                  width: "250px",
                }),
              }}
            />
          </InputContainer>
          <InputContainer width="400px">
            <InputLabel htmlFor="user_slack_id">Slack Id</InputLabel>
            <Input
              className="browser-default text-input-v2"
              style={{ width: "250px" }}
              type="text"
              value={state.slackId}
              onChange={handleInputChange}
              name="slackId"
              id="user_slack_id"
              placeholder="Slack Id"
            />
          </InputContainer>
        </FlexWrapper>
      )}
      {/* User privilege level */}
      {currentUserRoleId >= 4 && (
        <>
          <InputLabel style={{ marginTop: "2px" }} htmlFor="user_role_id">
            User Role
          </InputLabel>
          <RadioButtonGroup>
            {/* Render radio buttons for user roles */}
            {[1, 2, 3, 4, 5].map((role) => (
              <RadioButtonLabel key={role}>
                <RadioButton
                  type="radio"
                  className="with-gap"
                  value={role}
                  checked={roleId === role}
                  onChange={setRoleId}
                  name="user[role_id]"
                  id={`user_role_id_${role}`}
                />
                <RadioButtonSpan>{getRoleName(role)}</RadioButtonSpan>
              </RadioButtonLabel>
            ))}
          </RadioButtonGroup>
        </>
      )}

      {/* Employment Status */}
      {currentUserRoleId >= 4 && (
        <>
          <InputLabel style={{ marginTop: "2px" }} htmlFor="user_full_time">
            Employment Status
          </InputLabel>
          <RadioButtonGroup>
            {/* Render radio buttons for employment status */}
            <RadioButtonLabel>
              <RadioButton
                type="radio"
                className="with-gap"
                value={fullTime && !contractor}
                checked={fullTime && !contractor}
                name="user_full_time_true"
                onChange={setFullTime}
                id="user_full_time_true"
              />
              <RadioButtonSpan>Full-Time</RadioButtonSpan>
            </RadioButtonLabel>
            <RadioButtonLabel>
              <RadioButton
                type="radio"
                className="with-gap"
                value={!fullTime && !contractor}
                checked={!fullTime && !contractor}
                name="user_full_time_false"
                onChange={setFullTime}
                id="user_full_time_false"
              />
              <RadioButtonSpan>Part-Time</RadioButtonSpan>
            </RadioButtonLabel>
            <RadioButtonLabel>
              <RadioButton
                type="radio"
                className="with-gap"
                checked={contractor}
                onChange={setContractor}
                name="user_full_time"
                id="user_contractor"
              />
              <RadioButtonSpan onClick={setContractor}>
                Contractor
              </RadioButtonSpan>
            </RadioButtonLabel>
          </RadioButtonGroup>
        </>
      )}
      {/* Default Work Location */}
      {currentUserRoleId > 0 && (
        <>
          <InputLabel style={{ marginTop: "2px" }} htmlFor="user_work_location">
            Default Work Location
          </InputLabel>
          <RadioButtonGroup>
            <RadioButtonLabel>
              <RadioButton
                type="radio"
                className="with-gap"
                onChange={setWorkLocation}
                name="user[work_location]"
                id="user_work_from_home"
                value="true"
                checked={defaultWorkFromHome}
              />
              <RadioButtonSpan>Home</RadioButtonSpan>
            </RadioButtonLabel>
            <RadioButtonLabel>
              <RadioButton
                type="radio"
                className="with-gap"
                onChange={setWorkLocation}
                name="user[work_location]"
                id="user_work_from_office"
                value="false"
                checked={!defaultWorkFromHome}
              />
              <RadioButtonSpan>Office</RadioButtonSpan>
            </RadioButtonLabel>
          </RadioButtonGroup>
        </>
      )}
      {/* Taskboard Access */}
      {currentUserRoleId >= 4 && (
        <>
          <InputLabel style={{ marginTop: "2px" }} htmlFor="user_tb_acc">
            Taskboard Access
          </InputLabel>
          <RadioButtonGroup>
            <RadioButtonLabel>
              <RadioButton
                type="radio"
                className="with-gap"
                value={taskboardAccess}
                checked={taskboardAccess}
                name="user_tb_acc"
                data-true="true"
                onChange={handleAdditionPermission}
                id="user_tb_acc_t"
              />
              <RadioButtonSpan>Yes</RadioButtonSpan>
            </RadioButtonLabel>
            <RadioButtonLabel>
              <RadioButton
                type="radio"
                className="with-gap"
                value={taskboardAccess}
                checked={!taskboardAccess}
                name="user_tb_acc"
                onChange={handleAdditionPermission}
                id="user_tb_acc_f"
              />
              <RadioButtonSpan>No</RadioButtonSpan>
            </RadioButtonLabel>
          </RadioButtonGroup>
        </>
      )}
      <div style={{ display: "flex", gap: "4px", marginTop: "32px" }}>
        <SaveChangesButton type="submit" value="Save Changes" />
        {currentUserRoleId >= 4 && (
          <DeleteUserButton
            type="button"
            id="delete_user_button"
            value="Delete User "
            onClick={deleteUser}
          />
        )}
      </div>
    </Form>
  );
};

const Form = styled.form`
  padding: 32px;
`;

const SectionHeader = styled.p`
  margin: 0px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 32px;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.span`
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 2px;
`;

const Input = styled.input`
  margin: 0px;
`;

const ProfilePicContainer = styled.div`
  /* Add styles for the profile picture container */
`;

const RadioButtonGroup = styled.div`
  position: relative;
  left: -5px;
  margin-bottom: 16px;
`;
const RadioButtonLabel = styled.label``;
const RadioButton = styled.input``;
const RadioButtonSpan = styled.span`
  padding-left: 27px !important;
  margin-right: 16px;
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
`;

const SaveChangesButton = styled.input`
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #519acc;
  box-shadow: 0px 3px 0px 0px #578bc2;
  border: 0px solid #d7d7d7;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: var(--background-colors-workflow-status-578-bc-2, #578bc2);
    box-shadow: 0px 3px 0px 0px #578bc2;
  }
`;

const DeleteUserButton = styled.input`
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--Project-Type-A11y-Audit, #b82a23);
  box-shadow: 0px 3px 0px 0px #85201b;
  border: 0px solid #d7d7d7;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: #85201b;
    box-shadow: 0px 3px 0px 0px #85201b;
  }
`;

export default UserBasicInfoForm;
