import React from "react";
import styled from "styled-components";

const IndividualCapacity = ({ users }) => {
  return (
    <Container>
      <Title>Individual Capacity</Title>
      <ContainerList>
        {users.map((user, index) => (
          <Card key={index}>
            <NameWrapper>
              <UserName>{user.name}</UserName>
              <HoursAvailable>
                Hours Available: {user.availablePercentage}%
              </HoursAvailable>
            </NameWrapper>
            <ProgressBarContainer>
              {user.bookedPercentage > 0 && (
                <ProgressBar
                  width={user.bookedPercentage + "%"}
                  background="#519acc"
                  color="#fff"
                >
                  {user.bookedPercentage.toFixed(0) >= 12 &&
                    `${user.bookedPercentage.toFixed(0)}%`}
                </ProgressBar>
              )}
              {user.bookedPercentage < 100 && (
                <ProgressBar
                  width={100 - user.bookedPercentage + "%"}
                  background="#d7d7d7"
                  color="#000"
                >
                  {100 - user.bookedPercentage.toFixed(0) + "%"}
                </ProgressBar>
              )}
            </ProgressBarContainer>
          </Card>
        ))}
      </ContainerList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  max-height: 536px;
  overflow: hidden;
`;

const ContainerList = styled.div`
  max-height: 450px;
  overflow-y: auto;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.p`
  font-size: 16px;
  font-family: manrope;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 0px;
  line-height: 17px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  height: 64px;
`;

const UserName = styled.div`
  font-size: 15px;
  font-family: manrope;
  font-weight: 700;
  text-transform: capitalize;
`;

const HoursAvailable = styled.div`
  font-size: 14px;
  font-family: arial;
  font-weight: 400;
  color: #242424;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 26px;
  background: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  max-width: 313px;
`;

const ProgressBar = styled.div`
  height: 100%;
  width: ${(props) => props.width || "0%"};
  background: ${(props) => props.background || "#007bff"};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color || "#000"};
  font-size: 12px;
  font-family: Manrope;
  font-weight: 700;
`;

export default IndividualCapacity;
