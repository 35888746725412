import React, { useState, useEffect, useRef } from "react";
import RoundDropdown from "../projects/round_dropdown";
import styled from "styled-components";

const DropdownMenu = ({ options, selectedOption, onOptionChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    onOptionChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownSelector onClick={toggleDropdown}>
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {selectedOption?.label || "All"}
        </span>
        <ArrowWrapper isOpen={isOpen}>
          <RoundDropdown />
        </ArrowWrapper>
      </DropdownSelector>

      {isOpen && (
        <DropdownOptionWrapper>
          {options.map((option) => (
            <OptionItem
              key={option.label}
              onClick={() => handleOptionClick(option)}
              isSelected={selectedOption?.label === option.label}
            >
              {option.label}
            </OptionItem>
          ))}
        </DropdownOptionWrapper>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  width: 166px;
  font-family: Arial, sans-serif;
`;

const DropdownOptionWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const DropdownSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 39px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    border: 1px solid #519acc;
  }
`;

const ArrowWrapper = styled.span`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  display: flex;
  align-items: center;
`;

const OptionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? "#f0f8ff" : "transparent"};
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  color: ${({ isSelected }) => (isSelected ? "#519acc" : "#000")};
  &:hover {
    background-color: #e6f2ff;
  }
`;

export default DropdownMenu;
